import { Card, Skeleton, Stack } from '@mui/material'
import { memo } from 'react'

const ChallengeAlertSkeleton: React.FC = () => {
    return (
        <Card elevation={0} data-testid="challenge-alert-skeleton">
            <Stack
                p={2}
                direction="row"
                width="100%"
                bgcolor="grey.100"
                alignItems="center"
                justifyContent="space-between"
                gap={4}
            >
                <Stack gap={2} direction="row" alignItems="center" width="100%">
                    <Skeleton variant="rounded" height={40} width={40} />
                    <Stack width="100%">
                        <Skeleton height={22} width="100%" />
                        <Skeleton height={22} width="40%" />
                    </Stack>
                </Stack>
                <Stack>
                    <Skeleton variant="rounded" height={43.99} width={188.06} />
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeAlertSkeleton)
