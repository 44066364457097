import { Box, Button, Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Image from '../../../../components/elements/Image/Image'
import { UnifiedChallenge } from '../../../../graphql/generated/autogenerated'
import { useNavigateToChallenge } from '../../../../hooks/useNavigateToChallenge/useNavigateToChallenge'
import { ChallengeType } from '../../../../shared/enums/challengeType'

const recapPlantsUrl =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/f7915f8d-0422-4e78-face-6c60fd5b1d00/cms'

export type ViewChallengeRecapCardProps = {
    small?: boolean
    challenge: UnifiedChallenge
}

const ViewChallengeRecapCard: React.FC<ViewChallengeRecapCardProps> = ({
    small,
    challenge
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))

    const isSmall = useMemo(() => small || isMobile, [small, isMobile])

    const { navigate } = useNavigateToChallenge({
        challengeId: challenge?.id,
        challengeName: String(challenge?.name),
        challengeType: challenge?.challenge_type as ChallengeType,
        isActive: true
    })

    return (
        <Card variant="outlined">
            <Stack
                bgcolor="secondary.main"
                direction={isSmall ? 'column' : 'row'}
            >
                <Image
                    src={recapPlantsUrl}
                    height={isSmall ? 105 : 'auto'}
                    width={isSmall ? '100%' : 350}
                    cover={true}
                />
                <Stack gap={isSmall ? 2 : 3} p={2}>
                    <Stack gap={1}>
                        <CoreTypography
                            variant="h4"
                            color="primary.main"
                            component="h2"
                        >
                            <FormattedMessage
                                defaultMessage="Congrats! You just completed {challengeName}."
                                description="congrats you completed challenge title"
                                values={{
                                    challengeName: challenge?.name
                                }}
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1" color="primary.main">
                            <FormattedMessage
                                defaultMessage="We put together a recap of all the progress you made!"
                                description="challenge recap description"
                            />
                        </CoreTypography>
                    </Stack>
                    <Box>
                        <Button
                            variant="outlined"
                            onClick={navigate}
                            sx={{ backgroundColor: 'background.paper' }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="View Challenge Recap"
                                    description="view recap button"
                                />
                            </CoreTypography>
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ViewChallengeRecapCard)
