import { Button, Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import ChallengeAlertSkeleton from './ChallengeAlertSkeleton'

export interface ChallengeAlertProps {
    message: string
    icon?: JSX.Element
    buttonTitle?: string
    onButtonClick?: () => void
    loading?: boolean
}

const ChallengeAlert: React.FC<ChallengeAlertProps> = ({
    message,
    icon,
    buttonTitle,
    onButtonClick,
    loading
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    if (loading) return <ChallengeAlertSkeleton />

    const showButton = !!buttonTitle && !!onButtonClick

    return (
        <Card elevation={0}>
            <Stack
                p={2}
                direction={isMobile ? 'column' : 'row'}
                width="100%"
                bgcolor="background.paper"
                alignItems="center"
                justifyContent="space-between"
                gap={isMobile ? 2 : 4}
            >
                <Stack gap={2} direction="row" alignItems="center">
                    {icon}
                    <CoreTypography customVariant="form">
                        {message}
                    </CoreTypography>
                </Stack>
                {showButton && (
                    <Stack width={isMobile ? '100%' : 'auto'}>
                        <Button
                            variant="outlined"
                            size="medium"
                            onClick={onButtonClick}
                            sx={{ whiteSpace: 'nowrap' }}
                            fullWidth={isMobile}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {buttonTitle}
                            </CoreTypography>
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Card>
    )
}

export default memo(ChallengeAlert)
